:root {
    --spacing-unit: 2px;
}
a {
    cursor: pointer;
    text-decoration: none;
}

ul {
    margin: 0;
}

.height-100 {
    height: 100%;
}

.width-100 {
    width: 100% !important;
}

.text-cut {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.disable-events {
    pointer-events: none;
}

.flex-center {
    align-items: center;
    justify-content: center;
    display: flex;
}

.flex-space-between {
    align-items: center;
    justify-content: space-between;
    display: flex;
}

.text-start {
    text-align: left;
}

.text-center {
    text-align: center;
}

.text-end {
    text-align: right;
}
.align-items-start {
    align-items: flex-start;
}

.align-items-center {
    align-items: center;
}

.align-items-end {
    align-items: flex-end;
}
.justify-content-start {
    justify-content: flex-start;
}

.justify-content-center {
    justify-content: center;
}

.justify-content-end {
    justify-content: flex-end;
}

.justify-content-between {
    justify-content: space-between;
}

.justify-content-around {
    justify-content: space-around;
}

.align-self-start {
    align-self: flex-start;
}

.align-self-center {
    align-self: center;
}

.align-self-end {
    align-self: flex-end;
}

.align-self-stretch {
    align-self: stretch;
}

.df {
    display: flex;
}

.oh {
    overflow: hidden;
}

.dn {
    display: none;
}

.direction-column {
    flex-direction: column;
}

.position-center {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 99999999;
    transform: translate(-50%, -50%);
}

.ml-0 { margin-left: 0; }
.ml-1 { margin-left: calc(1 * var(--spacing-unit)); }
.ml-2 { margin-left: calc(2 * var(--spacing-unit)); }
.ml-3 { margin-left: calc(3 * var(--spacing-unit)); }
.ml-4 { margin-left: calc(4 * var(--spacing-unit)); }
.ml-5 { margin-left: calc(5 * var(--spacing-unit)); }
.ml-6 { margin-left: calc(6 * var(--spacing-unit)); }
.ml-7 { margin-left: calc(7 * var(--spacing-unit)); }
.ml-8 { margin-left: calc(8 * var(--spacing-unit)); }
.ml-9 { margin-left: calc(9 * var(--spacing-unit)); }
.ml-10 { margin-left: calc(10 * var(--spacing-unit)); }
.ml-11 { margin-left: calc(11 * var(--spacing-unit)); }
.ml-12 { margin-left: calc(12 * var(--spacing-unit)); }
.ml-13 { margin-left: calc(13 * var(--spacing-unit)); }
.ml-14 { margin-left: calc(14 * var(--spacing-unit)); }
.ml-15 { margin-left: calc(15 * var(--spacing-unit)); }
.ml-16 { margin-left: calc(16 * var(--spacing-unit)); }
.ml-17 { margin-left: calc(17 * var(--spacing-unit)); }
.ml-18 { margin-left: calc(18 * var(--spacing-unit)); }
.ml-19 { margin-left: calc(19 * var(--spacing-unit)); }
.ml-20 { margin-left: calc(20 * var(--spacing-unit)); }
.ml-auto { margin-left: auto }

.mr-0 { margin-right: 0; }
.mr-1 { margin-right: calc(1 * var(--spacing-unit)); }
.mr-2 { margin-right: calc(2 * var(--spacing-unit)); }
.mr-3 { margin-right: calc(3 * var(--spacing-unit)); }
.mr-4 { margin-right: calc(4 * var(--spacing-unit)); }
.mr-5 { margin-right: calc(5 * var(--spacing-unit)); }
.mr-6 { margin-right: calc(6 * var(--spacing-unit)); }
.mr-7 { margin-right: calc(7 * var(--spacing-unit)); }
.mr-8 { margin-right: calc(8 * var(--spacing-unit)); }
.mr-9 { margin-right: calc(9 * var(--spacing-unit)); }
.mr-10 { margin-right: calc(10 * var(--spacing-unit)); }
.mr-11 { margin-right: calc(11 * var(--spacing-unit)); }
.mr-12 { margin-right: calc(12 * var(--spacing-unit)); }
.mr-13 { margin-right: calc(13 * var(--spacing-unit)); }
.mr-14 { margin-right: calc(14 * var(--spacing-unit)); }
.mr-15 { margin-right: calc(15 * var(--spacing-unit)); }
.mr-16 { margin-right: calc(16 * var(--spacing-unit)); }
.mr-17 { margin-right: calc(17 * var(--spacing-unit)); }
.mr-18 { margin-right: calc(18 * var(--spacing-unit)); }
.mr-19 { margin-right: calc(19 * var(--spacing-unit)); }
.mr-20 { margin-right: calc(20 * var(--spacing-unit)); }
.mr-auto { margin-right: auto }

.br-0 { border-radius: 0; }
.br-1 { border-radius: calc(1 * var(--spacing-unit)); }
.br-2 { border-radius: calc(2 * var(--spacing-unit)); }
.br-3 { border-radius: calc(3 * var(--spacing-unit)); }
.br-4 { border-radius: calc(4 * var(--spacing-unit)); }
.br-5 { border-radius: calc(5 * var(--spacing-unit)); }
.br-6 { border-radius: calc(6 * var(--spacing-unit)); }
.br-7 { border-radius: calc(7 * var(--spacing-unit)); }
.br-8 { border-radius: calc(8 * var(--spacing-unit)); }
.br-9 { border-radius: calc(9 * var(--spacing-unit)); }
.br-10 { border-radius: calc(10 * var(--spacing-unit)); }
.br-11 { border-radius: calc(11 * var(--spacing-unit)); }
.br-12 { border-radius: calc(12 * var(--spacing-unit)); }
.br-13 { border-radius: calc(13 * var(--spacing-unit)); }
.br-14 { border-radius: calc(14 * var(--spacing-unit)); }
.br-15 { border-radius: calc(15 * var(--spacing-unit)); }
.br-16 { border-radius: calc(16 * var(--spacing-unit)); }
.br-17 { border-radius: calc(17 * var(--spacing-unit)); }
.br-18 { border-radius: calc(18 * var(--spacing-unit)); }
.br-19 { border-radius: calc(19 * var(--spacing-unit)); }
.br-20 { border-radius: calc(20 * var(--spacing-unit)); }

.brt-0 { border-top-left-radius: 0; border-top-right-radius: 0; }
.brt-1 { border-top-left-radius: calc(1 * var(--spacing-unit)); border-top-right-radius: calc(1 * var(--spacing-unit)); }
.brt-2 { border-top-left-radius: calc(2 * var(--spacing-unit)); border-top-right-radius: calc(2 * var(--spacing-unit)); }
.brt-3 { border-top-left-radius: calc(3 * var(--spacing-unit)); border-top-right-radius: calc(3 * var(--spacing-unit)); }
.brt-4 { border-top-left-radius: calc(4 * var(--spacing-unit)); border-top-right-radius: calc(4 * var(--spacing-unit)); }
.brt-5 { border-top-left-radius: calc(5 * var(--spacing-unit)); border-top-right-radius: calc(5 * var(--spacing-unit)); }
.brt-6 { border-top-left-radius: calc(6 * var(--spacing-unit)); border-top-right-radius: calc(6 * var(--spacing-unit)); }
.brt-7 { border-top-left-radius: calc(7 * var(--spacing-unit)); border-top-right-radius: calc(7 * var(--spacing-unit)); }
.brt-8 { border-top-left-radius: calc(8 * var(--spacing-unit)); border-top-right-radius: calc(8 * var(--spacing-unit)); }
.brt-9 { border-top-left-radius: calc(9 * var(--spacing-unit)); border-top-right-radius: calc(9 * var(--spacing-unit)); }
.brt-10 { border-top-left-radius: calc(10 * var(--spacing-unit)); border-top-right-radius: calc(10 * var(--spacing-unit)); }
.brt-11 { border-top-left-radius: calc(11 * var(--spacing-unit)); border-top-right-radius: calc(11 * var(--spacing-unit)); }
.brt-12 { border-top-left-radius: calc(12 * var(--spacing-unit)); border-top-right-radius: calc(12 * var(--spacing-unit)); }
.brt-13 { border-top-left-radius: calc(13 * var(--spacing-unit)); border-top-right-radius: calc(13 * var(--spacing-unit)); }
.brt-14 { border-top-left-radius: calc(14 * var(--spacing-unit)); border-top-right-radius: calc(14 * var(--spacing-unit)); }
.brt-15 { border-top-left-radius: calc(15 * var(--spacing-unit)); border-top-right-radius: calc(15 * var(--spacing-unit)); }
.brt-16 { border-top-left-radius: calc(16 * var(--spacing-unit)); border-top-right-radius: calc(16 * var(--spacing-unit)); }
.brt-17 { border-top-left-radius: calc(17 * var(--spacing-unit)); border-top-right-radius: calc(17 * var(--spacing-unit)); }
.brt-18 { border-top-left-radius: calc(18 * var(--spacing-unit)); border-top-right-radius: calc(18 * var(--spacing-unit)); }
.brt-19 { border-top-left-radius: calc(19 * var(--spacing-unit)); border-top-right-radius: calc(19 * var(--spacing-unit)); }
.brt-20 { border-top-left-radius: calc(20 * var(--spacing-unit)); border-top-right-radius: calc(20 * var(--spacing-unit)); }

.brb-0 { border-bottom-left-radius: 0; border-bottom-right-radius: 0; }
.brb-1 { border-bottom-left-radius: calc(1 * var(--spacing-unit)); border-bottom-right-radius: calc(1 * var(--spacing-unit)); }
.brb-2 { border-bottom-left-radius: calc(2 * var(--spacing-unit)); border-bottom-right-radius: calc(2 * var(--spacing-unit)); }
.brb-3 { border-bottom-left-radius: calc(3 * var(--spacing-unit)); border-bottom-right-radius: calc(3 * var(--spacing-unit)); }
.brb-4 { border-bottom-left-radius: calc(4 * var(--spacing-unit)); border-bottom-right-radius: calc(4 * var(--spacing-unit)); }
.brb-5 { border-bottom-left-radius: calc(5 * var(--spacing-unit)); border-bottom-right-radius: calc(5 * var(--spacing-unit)); }
.brb-6 { border-bottom-left-radius: calc(6 * var(--spacing-unit)); border-bottom-right-radius: calc(6 * var(--spacing-unit)); }
.brb-7 { border-bottom-left-radius: calc(7 * var(--spacing-unit)); border-bottom-right-radius: calc(7 * var(--spacing-unit)); }
.brb-8 { border-bottom-left-radius: calc(8 * var(--spacing-unit)); border-bottom-right-radius: calc(8 * var(--spacing-unit)); }
.brb-9 { border-bottom-left-radius: calc(9 * var(--spacing-unit)); border-bottom-right-radius: calc(9 * var(--spacing-unit)); }
.brb-10 { border-bottom-left-radius: calc(10 * var(--spacing-unit)); border-bottom-right-radius: calc(10 * var(--spacing-unit)); }
.brb-11 { border-bottom-left-radius: calc(11 * var(--spacing-unit)); border-bottom-right-radius: calc(11 * var(--spacing-unit)); }
.brb-12 { border-bottom-left-radius: calc(12 * var(--spacing-unit)); border-bottom-right-radius: calc(12 * var(--spacing-unit)); }
.brb-13 { border-bottom-left-radius: calc(13 * var(--spacing-unit)); border-bottom-right-radius: calc(13 * var(--spacing-unit)); }
.brb-14 { border-bottom-left-radius: calc(14 * var(--spacing-unit)); border-bottom-right-radius: calc(14 * var(--spacing-unit)); }
.brb-15 { border-bottom-left-radius: calc(15 * var(--spacing-unit)); border-bottom-right-radius: calc(15 * var(--spacing-unit)); }
.brb-16 { border-bottom-left-radius: calc(16 * var(--spacing-unit)); border-bottom-right-radius: calc(16 * var(--spacing-unit)); }
.brb-17 { border-bottom-left-radius: calc(17 * var(--spacing-unit)); border-bottom-right-radius: calc(17 * var(--spacing-unit)); }
.brb-18 { border-bottom-left-radius: calc(18 * var(--spacing-unit)); border-bottom-right-radius: calc(18 * var(--spacing-unit)); }
.brb-19 { border-bottom-left-radius: calc(19 * var(--spacing-unit)); border-bottom-right-radius: calc(19 * var(--spacing-unit)); }
.brb-20 { border-bottom-left-radius: calc(20 * var(--spacing-unit)); border-bottom-right-radius: calc(20 * var(--spacing-unit)); }

.mt-0 { margin-top: 0; }
.mt-1 { margin-top: calc(1 * var(--spacing-unit)); }
.mt-2 { margin-top: calc(2 * var(--spacing-unit)); }
.mt-3 { margin-top: calc(3 * var(--spacing-unit)); }
.mt-4 { margin-top: calc(4 * var(--spacing-unit)); }
.mt-5 { margin-top: calc(5 * var(--spacing-unit)); }
.mt-6 { margin-top: calc(6 * var(--spacing-unit)); }
.mt-7 { margin-top: calc(7 * var(--spacing-unit)); }
.mt-8 { margin-top: calc(8 * var(--spacing-unit)); }
.mt-9 { margin-top: calc(9 * var(--spacing-unit)); }
.mt-10 { margin-top: calc(10 * var(--spacing-unit)); }
.mt-11 { margin-top: calc(11 * var(--spacing-unit)); }
.mt-12 { margin-top: calc(12 * var(--spacing-unit)); }
.mt-13 { margin-top: calc(13 * var(--spacing-unit)); }
.mt-14 { margin-top: calc(14 * var(--spacing-unit)); }
.mt-15 { margin-top: calc(15 * var(--spacing-unit)); }
.mt-16 { margin-top: calc(16 * var(--spacing-unit)); }
.mt-17 { margin-top: calc(17 * var(--spacing-unit)); }
.mt-18 { margin-top: calc(18 * var(--spacing-unit)); }
.mt-19 { margin-top: calc(19 * var(--spacing-unit)); }
.mt-20 { margin-top: calc(20 * var(--spacing-unit)); }
.mt-auto { margin-top: auto }

.mb-0 { margin-bottom: 0; }
.mb-1 { margin-bottom: calc(1 * var(--spacing-unit)); }
.mb-2 { margin-bottom: calc(2 * var(--spacing-unit)); }
.mb-3 { margin-bottom: calc(3 * var(--spacing-unit)); }
.mb-4 { margin-bottom: calc(4 * var(--spacing-unit)); }
.mb-5 { margin-bottom: calc(5 * var(--spacing-unit)); }
.mb-6 { margin-bottom: calc(6 * var(--spacing-unit)); }
.mb-7 { margin-bottom: calc(7 * var(--spacing-unit)); }
.mb-8 { margin-bottom: calc(8 * var(--spacing-unit)); }
.mb-9 { margin-bottom: calc(9 * var(--spacing-unit)); }
.mb-10 { margin-bottom: calc(10 * var(--spacing-unit)); }
.mb-11 { margin-bottom: calc(11 * var(--spacing-unit)); }
.mb-12 { margin-bottom: calc(12 * var(--spacing-unit)); }
.mb-13 { margin-bottom: calc(13 * var(--spacing-unit)); }
.mb-14 { margin-bottom: calc(14 * var(--spacing-unit)); }
.mb-15 { margin-bottom: calc(15 * var(--spacing-unit)); }
.mb-16 { margin-bottom: calc(16 * var(--spacing-unit)); }
.mb-17 { margin-bottom: calc(17 * var(--spacing-unit)); }
.mb-18 { margin-bottom: calc(18 * var(--spacing-unit)); }
.mb-19 { margin-bottom: calc(19 * var(--spacing-unit)); }
.mb-20 { margin-bottom: calc(20 * var(--spacing-unit)); }
.mb-auto { margin-bottom: auto; }

.p-0 { padding: 0; }
.p-1 { padding: calc(1 * var(--spacing-unit)); }
.p-2 { padding: calc(2 * var(--spacing-unit)); }
.p-3 { padding: calc(3 * var(--spacing-unit)); }
.p-4 { padding: calc(4 * var(--spacing-unit)); }
.p-5 { padding: calc(5 * var(--spacing-unit)); }
.p-6 { padding: calc(6 * var(--spacing-unit)); }
.p-7 { padding: calc(7 * var(--spacing-unit)); }
.p-8 { padding: calc(8 * var(--spacing-unit)); }
.p-9 { padding: calc(9 * var(--spacing-unit)); }
.p-10 { padding: calc(10 * var(--spacing-unit)); }
.p-11 { padding: calc(11 * var(--spacing-unit)); }
.p-12 { padding: calc(12 * var(--spacing-unit)); }
.p-13 { padding: calc(13 * var(--spacing-unit)); }
.p-14 { padding: calc(14 * var(--spacing-unit)); }
.p-15 { padding: calc(15 * var(--spacing-unit)); }
.p-16 { padding: calc(16 * var(--spacing-unit)); }
.p-17 { padding: calc(17 * var(--spacing-unit)); }
.p-18 { padding: calc(18 * var(--spacing-unit)); }
.p-19 { padding: calc(19 * var(--spacing-unit)); }
.p-20 { padding: calc(20 * var(--spacing-unit)); }

.pt-0 { padding-top: 0; }
.pt-1 { padding-top: calc(1 * var(--spacing-unit)); }
.pt-2 { padding-top: calc(2 * var(--spacing-unit)); }
.pt-3 { padding-top: calc(3 * var(--spacing-unit)); }
.pt-4 { padding-top: calc(4 * var(--spacing-unit)); }
.pt-5 { padding-top: calc(5 * var(--spacing-unit)); }
.pt-6 { padding-top: calc(6 * var(--spacing-unit)); }
.pt-7 { padding-top: calc(7 * var(--spacing-unit)); }
.pt-8 { padding-top: calc(8 * var(--spacing-unit)); }
.pt-9 { padding-top: calc(9 * var(--spacing-unit)); }
.pt-10 { padding-top: calc(10 * var(--spacing-unit)); }
.pt-11 { padding-top: calc(11 * var(--spacing-unit)); }
.pt-12 { padding-top: calc(12 * var(--spacing-unit)); }
.pt-13 { padding-top: calc(13 * var(--spacing-unit)); }
.pt-14 { padding-top: calc(14 * var(--spacing-unit)); }
.pt-15 { padding-top: calc(15 * var(--spacing-unit)); }
.pt-16 { padding-top: calc(16 * var(--spacing-unit)); }
.pt-17 { padding-top: calc(17 * var(--spacing-unit)); }
.pt-18 { padding-top: calc(18 * var(--spacing-unit)); }
.pt-19 { padding-top: calc(19 * var(--spacing-unit)); }
.pt-20 { padding-top: calc(20 * var(--spacing-unit)); }

.pb-0 { padding-bottom: 0; }
.pb-1 { padding-bottom: calc(1 * var(--spacing-unit)); }
.pb-2 { padding-bottom: calc(2 * var(--spacing-unit)); }
.pb-3 { padding-bottom: calc(3 * var(--spacing-unit)); }
.pb-4 { padding-bottom: calc(4 * var(--spacing-unit)); }
.pb-5 { padding-bottom: calc(5 * var(--spacing-unit)); }
.pb-6 { padding-bottom: calc(6 * var(--spacing-unit)); }
.pb-7 { padding-bottom: calc(7 * var(--spacing-unit)); }
.pb-8 { padding-bottom: calc(8 * var(--spacing-unit)); }
.pb-9 { padding-bottom: calc(9 * var(--spacing-unit)); }
.pb-10 { padding-bottom: calc(10 * var(--spacing-unit)); }
.pb-11 { padding-bottom: calc(11 * var(--spacing-unit)); }
.pb-12 { padding-bottom: calc(12 * var(--spacing-unit)); }
.pb-13 { padding-bottom: calc(13 * var(--spacing-unit)); }
.pb-14 { padding-bottom: calc(14 * var(--spacing-unit)); }
.pb-15 { padding-bottom: calc(15 * var(--spacing-unit)); }
.pb-16 { padding-bottom: calc(16 * var(--spacing-unit)); }
.pb-17 { padding-bottom: calc(17 * var(--spacing-unit)); }
.pb-18 { padding-bottom: calc(18 * var(--spacing-unit)); }
.pb-19 { padding-bottom: calc(19 * var(--spacing-unit)); }
.pb-20 { padding-bottom: calc(20 * var(--spacing-unit)); }

.pl-0 { padding-left: 0; }
.pl-1 { padding-left: calc(1 * var(--spacing-unit)); }
.pl-2 { padding-left: calc(2 * var(--spacing-unit)); }
.pl-3 { padding-left: calc(3 * var(--spacing-unit)); }
.pl-4 { padding-left: calc(4 * var(--spacing-unit)); }
.pl-5 { padding-left: calc(5 * var(--spacing-unit)); }
.pl-6 { padding-left: calc(6 * var(--spacing-unit)); }
.pl-7 { padding-left: calc(7 * var(--spacing-unit)); }
.pl-8 { padding-left: calc(8 * var(--spacing-unit)); }
.pl-9 { padding-left: calc(9 * var(--spacing-unit)); }
.pl-10 { padding-left: calc(10 * var(--spacing-unit)); }
.pl-11 { padding-left: calc(11 * var(--spacing-unit)); }
.pl-12 { padding-left: calc(12 * var(--spacing-unit)); }
.pl-13 { padding-left: calc(13 * var(--spacing-unit)); }
.pl-14 { padding-left: calc(14 * var(--spacing-unit)); }
.pl-15 { padding-left: calc(15 * var(--spacing-unit)); }
.pl-16 { padding-left: calc(16 * var(--spacing-unit)); }
.pl-17 { padding-left: calc(17 * var(--spacing-unit)); }
.pl-18 { padding-left: calc(18 * var(--spacing-unit)); }
.pl-19 { padding-left: calc(19 * var(--spacing-unit)); }
.pl-20 { padding-left: calc(20 * var(--spacing-unit)); }

.pr-0 { padding-right: 0; }
.pr-1 { padding-right: calc(1 * var(--spacing-unit)); }
.pr-2 { padding-right: calc(2 * var(--spacing-unit)); }
.pr-3 { padding-right: calc(3 * var(--spacing-unit)); }
.pr-4 { padding-right: calc(4 * var(--spacing-unit)); }
.pr-5 { padding-right: calc(5 * var(--spacing-unit)); }
.pr-6 { padding-right: calc(6 * var(--spacing-unit)); }
.pr-7 { padding-right: calc(7 * var(--spacing-unit)); }
.pr-8 { padding-right: calc(8 * var(--spacing-unit)); }
.pr-9 { padding-right: calc(9 * var(--spacing-unit)); }
.pr-10 { padding-right: calc(10 * var(--spacing-unit)); }
.pr-11 { padding-right: calc(11 * var(--spacing-unit)); }
.pr-12 { padding-right: calc(12 * var(--spacing-unit)); }
.pr-13 { padding-right: calc(13 * var(--spacing-unit)); }
.pr-14 { padding-right: calc(14 * var(--spacing-unit)); }
.pr-15 { padding-right: calc(15 * var(--spacing-unit)); }
.pr-16 { padding-right: calc(16 * var(--spacing-unit)); }
.pr-17 { padding-right: calc(17 * var(--spacing-unit)); }
.pr-18 { padding-right: calc(18 * var(--spacing-unit)); }
.pr-19 { padding-right: calc(19 * var(--spacing-unit)); }
.pr-20 { padding-right: calc(20 * var(--spacing-unit)); }

.ga-0 { gap: 0; }
.ga-1 { gap: calc(1 * var(--spacing-unit)); }
.ga-2 { gap: calc(2 * var(--spacing-unit)); }
.ga-3 { gap: calc(3 * var(--spacing-unit)); }
.ga-4 { gap: calc(4 * var(--spacing-unit)); }

.m-auto {
    margin: auto;
}

.fw-600 {
    font-weight: 600;
}

input,
textarea {
    border: none;
    outline: none;
}

input,
textarea,
button {
    font-family: 'Poppins', sans-serif;
}

/*vertical scroll-bar styles*/
.custom-vertical-scroll .simplebar-scrollbar {
    border-radius: 2px !important;
}
.custom-vertical-scroll .simplebar-scrollbar,
.custom-vertical-scroll .simplebar-scrollbar:before {
    background-color: rgba(51, 51, 51, 0.25) !important;
}
.custom-vertical-scroll .simplebar-track.simplebar-vertical {
    width: 3px !important;
}
.custom-vertical-scroll .simplebar-content-wrapper {
    outline: none;
}

@keyframes fadeInOut {
    0%, 100% { opacity: 0; }
    50% { opacity: 1; }
}

.fade-in-out {
    animation: fadeInOut .3s ease-in-out infinite;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

.fade-in {
    animation: fadeIn .4s;
}

@keyframes slideInFromBottom {
    from {
        transform: translateY(100%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.slide-in-bottom {
    animation: slideInFromBottom 1s ease-out;
}

@keyframes scaleUp {
    from {
        transform: scale(0);
        opacity: 0;
    }
    to {
        transform: scale(1);
        opacity: 1;
    }
}

.scale-up {
    animation: scaleUp 1s ease-in-out;
}
@keyframes slideInFromLeft {
    from {
        transform: translateX(-100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

.slide-in-left {
    animation: slideInFromLeft 1s ease-out;
}

@keyframes fadeInBlur {
    from {
        filter: blur(5px);
        opacity: 0;
    }
    to {
        filter: blur(0);
        opacity: 1;
    }
}

.fade-in-blur {
    animation: fadeInBlur 2s ease-out;
}
.fw-100 {
    font-weight: 100;
}

.fw-200 {
    font-weight: 200;
}

.fw-300 {
    font-weight: 300;
}

.fw-400 {
    font-weight: 400;
}

.fw-500 {
    font-weight: 500;
}

.fw-600 {
    font-weight: 600;
}

.fz-8 {
    font-size: 8px;
}

.fz-10 {
    font-size: 10px;
}

.fz-12 {
    font-size: 12px;
}

.fz-14 {
    font-size: 14px;
}

.fz-16 {
    font-size: 16px;
}

.fz-18 {
    font-size: 18px;
}

.fz-20 {
    font-size: 20px;
}

.fz-22 {
    font-size: 22px;
}

.fz-24 {
    font-size: 24px;
}

.fz-26 {
    font-size: 26px;
}

.fz-28 {
    font-size: 28px;
}

.fz-30 {
    font-size: 30px;
}

.fz-32 {
    font-size: 32px;
}

.fz-34 {
    font-size: 34px;
}

.fz-36 {
    font-size: 36px;
}

.fz-38 {
    font-size: 38px;
}

.fz-40 {
    font-size: 40px;
}

.fw-700 {
    font-weight: 700;
}

.fw-800 {
    font-weight: 800;
}

.fw-900 {
    font-weight: 900;
}